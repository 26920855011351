import React from 'react';
import './Table.scss';

function TableItem(props) {
    return <div className="TableItem">{props.children}</div>;
}

TableItem.propTypes = {};

export default TableItem;
