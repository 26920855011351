import React from 'react';
import './Table.scss';

function Table(props) {
    let mod_class = [
        'Table',
        props.variant !== undefined ? 'variant-' + props.variant : '',
    ];
    mod_class = mod_class.join(' ');

    return <div className={mod_class}>{props.children}</div>;
}

Table.propTypes = {};

export default Table;
